<template>
    <div>
        <div class="Qrcode"  >
            <!-- 生成二维码 -->
            <div id="qrcode" class="share"></div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    export default {
        props:{
            url:{
                type:String,
                default:''
            },
            width:{
                type:Number,
                default:80
            },
            height:{
                type:Number,
                default:80
            }
        },
        name: "makeSharePic",
        data() {
            return {
                qrcode:null
            }
        },
        watch:{
            url:function(url){
                this.qrcode.clear(); // 清除代码
                this.qrcode.makeCode(url); // 生成另外一个二维码
            }
        },
        methods: {

        },
        mounted() {

            this.qrcode = new QRCode('qrcode', {
                width: this.width,//二维码宽度
                height: this.height,//二维码高度
                text:  this.url, // 二维码内容
                colorDark: "#000",
                colorLight: "#fff",
            })
            console.log(this.qrcode)
        }
    }
</script>

<style >

</style>